import React from 'react'
import { SectionComparison } from '../../../types/sections'
import { SECTION_MARGIN } from '../../../utils/constants'
import { Box, Button, Flex, Heading, Text } from '../../../atoms'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { renderNodeRule, StructuredText } from 'react-datocms'
import { isList, isListItem, isParagraph } from 'datocms-structured-text-utils'
import CheckLarge from '../../../atoms/Icons/CheckLarge'
import CrossLarge from '../../../atoms/Icons/CrossLarge'
import { StaticImage } from 'gatsby-plugin-image'

function Comparison(props: SectionComparison): JSX.Element {
  const { id, sectionId, headline, comparisonBlocks, buttons } = props

  return (
    <Box position={'relative'} as={'section'} id={sectionId ?? id} my={SECTION_MARGIN} overflow={'hidden'}>
      <Grid>
        <Box position={'relative'}>
          <Box display={['none', 'none', 'block']} position={'absolute'} top={0} left={0} zIndex={0}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../../../images/synapse_1.png'}
              alt={''}
              placeholder={'none'}
              width={46}
            />
          </Box>
          <Box display={['none', 'none', 'block']} position={'absolute'} zIndex={0} right={'-500px'} bottom={-40}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../../../images/ellipse_bl.png'}
              alt={''}
              placeholder={'none'}
              width={500}
            />
          </Box>
          <Row center={'xs'}>
            <Col xs={12} sm={10} md={8}>
              <Heading as={'h2'} type={'heading1'} textAlign={'center'}>
                {headline}
              </Heading>
            </Col>
          </Row>

          <Box my={[40, 40, 64]}>
            <Row style={{ gap: '32px 0' }}>
              {comparisonBlocks.map((cb) => (
                <Col key={cb.id} xs={12} md={6}>
                  <Box
                    p={[24, 24, 32]}
                    backgroundColor={cb.highlighted ? 'violetLight' : 'white'}
                    boxShadow={cb.highlighted ? 'cardDarkBlue' : 'card'}
                    borderColor={cb.highlighted ? 'darkBlue' : 'black'}
                    borderStyle={'solid'}
                    borderWidth={'1px'}
                    borderRadius={8}
                    height={'100%'}
                  >
                    {cb.title && (
                      <Box mb={6}>
                        <Heading as={'h3'} type={'heading3'} textAlign={'center'}>
                          {cb.title}
                        </Heading>
                      </Box>
                    )}

                    <StructuredText
                      data={cb.content}
                      customNodeRules={[
                        renderNodeRule(isParagraph, ({ children, key, ancestors }) => {
                          if (isListItem(ancestors[0])) {
                            return (
                              <Text type={'large'} key={key}>
                                {children}
                              </Text>
                            )
                          } else {
                            return (
                              <Box mb={[4, 5, 6]} key={key}>
                                <Text type={'large'}>{children}</Text>
                              </Box>
                            )
                          }
                        }),
                        renderNodeRule(isList, ({ node, children, key }) => {
                          return (
                            <Box key={key} as={node.style === 'numbered' ? 'ol' : 'ul'} mt={[4, 5, 6]} pl={0}>
                              {children}
                            </Box>
                          )
                        }),
                        renderNodeRule(isListItem, ({ children, key }) => {
                          return (
                            <Box key={key} as={'li'} my={4} style={{ listStyle: 'none' }}>
                              <Flex justifyContent={'space-between'}>
                                <Box mr={3} flex={'0 0 auto'}>
                                  {cb.highlighted ? <CheckLarge /> : <CrossLarge />}
                                </Box>
                                <Box flex={'1'}>{children}</Box>
                              </Flex>
                            </Box>
                          )
                        }),
                      ]}
                    />
                  </Box>
                </Col>
              ))}
            </Row>
          </Box>

          <Row>
            <Col xs={12}>
              {buttons.length > 0 && (
                <Flex justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'} rowGap={4}>
                  {buttons.map((button) => (
                    <Box key={button.id}>
                      <Button key={button.id} {...button} />
                    </Box>
                  ))}
                </Flex>
              )}
            </Col>
          </Row>
        </Box>
      </Grid>
    </Box>
  )
}

export default Comparison
